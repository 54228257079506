<template>
  <el-cascader v-model="selectedClass"
               :style="marginLeft ? 'margin-left: 10px;' : ''"
               :options="gradeLsit"
               placeholder="请选择班级"
               :clearable="clearCascader?false:true"
               @change="selectClassChange"></el-cascader>
</template>

<script>
export default {
  name: "classSelector",
  props: {
    //是否默认第一项
    defaultFirst: Boolean,
    //margin-left: 10px;
    marginLeft: Boolean,
    //初始值
    initialValue: {
      type: Number,
      default: 0,
    },
    clearCascader: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    initialValue(newValue) {
      let gradeList = JSON.parse(sessionStorage.getItem("userInfo")).gradeList;
      let length1 = gradeList.length;
      if (newValue === 0) {
        this.selectedClass = [];
      } else {
        for (let i = 0; i < length1; i++) {
          let length2 = gradeList[i].list.length;
          for (let j = 0; j < length2; j++) {
            if (gradeList[i].list[j].classid == newValue) {
              this.selectedClass = [gradeList[i].gradeid, newValue];
              break;
            }
          }
        }
      }
    },
  },
  data() {
    return {
      selectedClass: [],
      gradeLsit: [],
    };
  },
  created() {
    this.classVisible = true;
    let gradeLsit = JSON.parse(sessionStorage.getItem("userInfo")).gradeList;
    gradeLsit.forEach((garde) => {
      garde.value = garde.gradeid;
      garde.label = garde.gradename;
      garde.children = garde.list;
      garde.children.forEach((cls) => {
        cls.value = cls.classid;
        cls.label = cls.classname;
        //匹配出与初始值的id相同的班级
        if (cls.classid === this.initialValue) {
          this.selectedClass = [garde.gradeid, cls.classid];
        }
      });
    });

    if (
      this.defaultFirst &&
      this.gradeLsit[0] &&
      this.gradeLsit[0].children[0]
    ) {
      this.selectedClass = this.gradeLsit[0].children[0].value;
    }
    this.gradeLsit = gradeLsit;
    this.selectedClass = this.initialValue;
  },
  methods: {
    selectClassChange(value) {
      console.log(value[1] || "");
      this.$emit("change", value[1] || "");
    },
  },
};
</script>

<style scoped>
</style>
