<template>
    <div class="teachingPatrolDetails">
        <div class="flex-box">
            <div class="left">
            </div>
            <div v-if="!roleType">
                <classSelector @change="classChange"></classSelector>
                <el-button type="primary"
                        @click="loadPatrolDetails(1)">查 询
                </el-button>
            </div>
        </div>
        <el-table
            :data="patrolDetailsList"
            style="width: 100%">
            <el-table-column
                label="班级"
                prop="cname"
                align="center"></el-table-column>
            <el-table-column
                label="节次"
                prop="check_time"
                align="center"></el-table-column>
            <el-table-column
                label="上课教师"
                prop="mname"
                align="center"></el-table-column>
            <el-table-column
                label="班级评分"
                prop="score"
                align="center"></el-table-column>
            <el-table-column
                label="评价"
                prop="content"
                align="center"></el-table-column>
            <el-table-column
                label="操作"
                width="500px"
                prop="course"
                align="center">
                <template slot-scope="scope">
                    <!-- <el-button
                    type="primary"
                    @click="lookCompletion(scope.row)">查看完成情况
                    </el-button> -->
                    <el-button
                    type="success"
                    @click="editPatrolDetail(scope.row)">修改
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background
            layout="prev,pager,next"
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="loadPatrolDetails"></el-pagination>

        <el-dialog
            title="修改课堂巡查"
            :visible.sync="editPatrolDetailDialogVisible"
            width="600px">
            <div class="center">
                <el-form
                :model="editPatrolDetailForm"
                ref="editPatrolDetailFormRef"
                label-width="100px"
                :rules="editPatrolDetailFormRules">
                    <!-- 分值 -->
                    <el-form-item label="分值：" prop="score">
                        <el-input
                            placeholder="请输入分值"
                            v-model="editPatrolDetailForm.score"
                            clearable>
                        </el-input>
                    </el-form-item>
                    <!-- 评价 -->
                    <el-form-item label="评价：" prop="content">
                        <el-input
                            placeholder="请输入评价"
                            v-model="editPatrolDetailForm.content"
                            clearable>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 确认与取消 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="editPatrolDetailDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editPatrolDetailConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// 导入班级组件
import classSelector from "@/components/selectors/classSelector";

export default {
    components:{
        classSelector
    },
    name:'teachingPatrolDetails',
    data(){
        return {
            page: { currentPage: 0, pageCount: 1 },
            // 巡查id
            id:Number(this.$route.query.id),
            // 巡查情况列表
            patrolDetailsList:[],
            // 模块管理员列表
            moduleManagementList:JSON.parse(sessionStorage.getItem('userInfo')).nav,
            // 是否为模块管理员
            roleType:false,
            // 查询对象
            query:{
                classId:''
            },
            // 编辑巡查情况对话框显示与隐藏
            editPatrolDetailDialogVisible:false,
            // 编辑巡查情况对话框表单
            editPatrolDetailForm:{
                id:'', //必传
                score:'',
                content:'',
            },
            // 表单验证规则
            editPatrolDetailFormRules:{
                managerid: [{required: true}],
                check_time: [{required: true}],
            },
        }
    },
    created(){
        // 判断角色
        // 循环模块管理员列表
        for(let i = 0; i < this.moduleManagementList.length; i++){
            console.log(1)
            if(this.moduleManagementList[i].id===111){
                // 模块管理员
                this.roleType = true
                // break
            }
        }
        this.loadPatrolDetails(1)
    },
    methods:{
        // 查看巡查情况
        loadPatrolDetails(page){
            let data
            let url
            if(this.roleType){
                // 模块管理员
                data = {
                    id:this.id
                }
                url = `/media/queryInspectionDetailById.do`
            }else{
                // 巡查人(教师)
                data = {
                    indexNo:page,
                    classid:'',
                    start_time:'',
                    end_time:'',
                    inspection_id:this.id
                }
                url = `/media/queryInspectionDetailByTea.do`
            }
    
            this.$post(url,data)
            .then(res=>{
                res.data.forEach(item=>{
                    item.sort_hour = '第' + item.sort_hour + '节课'
                })
                this.patrolDetailsList = res.data
            })
        },
        // 查看完成情况
        lookCompletion(){
            
        },
        // 选中班级时
        classChange(value){
            this.query.classId = value
        },
        // 编辑
        editPatrolDetail(row){
		  // 显示对话框
		  this.editPatrolDetailDialogVisible = true
            // 把数据赋值给编辑表单
            this.$nextTick(()=>{
                this.editPatrolDetailForm.id = row.id
                this.editPatrolDetailForm.score = row.score
                this.editPatrolDetailForm.content = row.content
            })
        },
        // 编辑表单
        editPatrolDetailConfirm(){
            this.$refs.editPatrolDetailFormRef.validate(validate => {
                if(validate){
                    // 编辑审批人表单提交
                    if(this.editPatrolDetailForm.id){
                        this.$post('/media/updateInspectionDetail.do',this.editPatrolDetailForm)
                        .then(res => {
						  this.$message.success('编辑成功')
                            this.editPatrolDetailDialogVisible = false;
                            // 更新当前页数据列表
                            this.loadPatrolDetails(this.page.currentPage)
                        })
                    }
                }

            })
        },
    }
}
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
